import { CONSTANTS } from "../config";

export default function useModal() {
    let container;
    let dialog;
    let openButton;
    let closeButton;
    let counter;
    const body = document.body;

    const maxTime = CONSTANTS.modal.timeout;
    let remainingTime = maxTime;
    let timerInterval;

    const setup = (el) => {
        container = el;
        dialog = container.querySelector('dialog');
        openButton = container.querySelector(CONSTANTS.modal.dom.openButton);
        closeButton = container.querySelector(CONSTANTS.modal.dom.closeButton);
        counter = container.querySelector(CONSTANTS.modal.dom.counter);

        addListeners();

        const hasBeenOpened = checkIfFirstTime();

        if(!hasBeenOpened) {
            autoOpenModal();
        } else {
            hideCountdown();
        }
    }

    const checkIfFirstTime = () => {
        const modalWasOpened = localStorage.getItem("modalWasOpened");
        return !!modalWasOpened;
    }

    const autoOpenModal = () => {
        toggleCountdown();
        updateCountdown();

        timerInterval = setInterval(()=>{
            console.log('interval', remainingTime);
            remainingTime--;
            updateCountdown();

            if(remainingTime<=0) {
                openButton.click();
                localStorage.setItem("modalWasOpened", true)
                cancelSetInterval();
            }
        }, 1000);
    }

    const updateCountdown = () => {
        counter.innerHTML = remainingTime;
    }

    const toggleCountdown = () => {
        counter.classList.toggle('hidden')
    }

    const cancelSetInterval = () => {
        clearInterval(timerInterval);
        toggleCountdown();
    }

    const addListeners = () => {
        openButton.addEventListener('click', openModal);
        closeButton.addEventListener('click', closeModal);
        body.addEventListener('keydown', onModalKeyPress);
        dialog.addEventListener('click', handleDialogClick)
    }

    const handleDialogClick = (e) => {
        const dialogDimensions = dialog.getBoundingClientRect()
        if (
            e.clientX < dialogDimensions.left ||
            e.clientX > dialogDimensions.right ||
            e.clientY < dialogDimensions.top ||
            e.clientY > dialogDimensions.bottom
        ) {
            closeModal();
        }
    }

    const openModal = () => {
        body.classList.add(CONSTANTS.modal.classes.modalOpen);
        dialog.showModal();
    }
    const closeModal = () => {
        body.classList.remove(CONSTANTS.modal.classes.modalOpen);
        dialog.close();
    }

    const onModalKeyPress = (e) => {
        if(e.key === 'Escape') {
            closeModal();
        }
    }

    return {
        setup
    }
}