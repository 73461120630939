const _CONSTANTS = {
  menu: {
    dom: {
      toggleBtns: '[data-toggle-menu]',
      menu: '#menu',
    }
  },
  howto: {
    dom: {
      base: '[data-howto]',
      osToggle: '[data-howto-toggle]',
      osToggleBtn: '[data-howto-choice]',
      stepSlide: '[data-howto-step]',
      stepBtn: '[data-step-nav]',
      stepLbl: '[data-step-label]',
      nextBtn: '[data-step-next]',
      prevBtn: '[data-step-prev]',
    },
    values: {
      startingOS: 'ios',
    },
    classes: {
      activeOS: 'howto__guide--active',
      activeOSbtn: 'howto__button--active',
      activeStep: 'howto__step--active',
    }
  },
  modal: {
    dom: {
      modal: '[data-modal]',
      counter: '[data-modal-counter]',
      openButton: '[data-modal-toggle="open"]',
      closeButton: '[data-modal-toggle="close"]',
    },
    classes: {
      modalOpen: 'modal-is-open',
    },
    timeout: 5,
  }
}

export const CONSTANTS = Object.freeze(_CONSTANTS);