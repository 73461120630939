import { CONSTANTS } from './config';
import HowTo from './modules/howto';
import MenuHandler from './menu';
import useModal from './modules/modal';

function init() {
  console.info('@app - init');
  initHowToGuide();
  initMenuHandlers();
  initTemporaryModal();
}

function initHowToGuide() {
  const howto = document.querySelector(CONSTANTS.howto.dom.base);

  if(!document.body.contains(howto))
    return false;

  new HowTo(howto);
}

function initMenuHandlers() {
  const menuToggleButton = document.querySelector(CONSTANTS.menu.toggleBtn);

  new MenuHandler();
}

function initTemporaryModal() {
  const modal = document.querySelector(CONSTANTS.modal.dom.modal);

  if(!document.body.contains(modal))
    return false;

  console.log('prepare modal to be shown');

  const { setup } = useModal();

  setup(modal);

}

document.addEventListener("DOMContentLoaded", init);